<template>
	<v-dialog
		class="edit-note-dialog"
		v-model="open"
		:persistent="isDirty"
		:max-width="800"
		:fullscreen="$vuetify.breakpoint.smAndDown"
	>
		<sz-content-card v-if="item" :title="$t(open && item.id ? `comp.noteBoard.editNote` : `comp.noteBoard.newNote`)">
			<template v-slot:content>
				<v-layout row wrap>
					<v-flex xs12 md6 pa-3>
						<sz-date-picker-input
							v-model="item.start"
							:label="$t(`generic.date.StartDate`)"
							prepend-icon="calendar_today"
							:min-date="today"
							:max-date="item.end"
						/>
					</v-flex>

					<v-flex xs12 md6 pa-3>
						<sz-date-picker-input
							v-model="item.end"
							clearable
							prepend-icon="date_range"
							:label="$t(`generic.date.EndDate`)"
							:min-date="item.start"
						/>
					</v-flex>
				</v-layout>

				<v-layout row wrap>
					<!-- <v-flex xs12 md9 pa-3>
						<v-flex xs9>
							<v-select
								class="icon-select"
								v-model="item.icon"
								:label="$t(`generic.icon`)"
								:prepend-icon="item.icon"
								hide-details
								menu-props="auto"
								:items="[
									{ value: 'priority_high', text: $t('comp.noteBoard.Exclamation') },

									{ value: 'comment', text: $t('comp.noteBoard.Comment') },
									{ value: 'thumb_up_alt', text: $t('comp.noteBoard.Thumb up') },
									{ value: 'thumb_down_alt', text: $t('comp.noteBoard.Thumb down') },
									{ value: 'local_parking', text: $t('comp.noteBoard.Parking') },
									{ value: 'warning', text: $t('comp.noteBoard.Warning') },
									{ value: 'sentiment_satisfied_alt', text: $t('comp.noteBoard.Smiley') },
									{ value: 'group', text: $t('comp.noteBoard.Group') },
									{ value: 'build', text: $t('comp.noteBoard.Wrench') },
									{ value: 'error_outline', text: $t('comp.noteBoard.Error') },
									{ value: 'gavel', text: $t('comp.noteBoard.Gavel') },
									{ value: 'alarm', text: $t('comp.noteBoard.Alarm') },
									{ value: 'settings', text: $t('comp.noteBoard.Gear') },

									{ value: 'i', text: $t('comp.noteBoard.Information'), plain: true },

									// { value: &quot;gear&quot;, text: $t(&quot;Gear&quot;) },
									// { value: &quot;speech-bubble&quot;, text: $t(&quot;Speech bubble&quot;) },
									// { value: &quot;star&quot;, text: $t(&quot;Star&quot;) },
									// { value: &quot;warning-triangle&quot;, text: $t(&quot;Warning triangle&quot;) },
								]"
							>
								<template slot="item" slot-scope="props">
									<v-list-item-content class="icon-select__item">
										<v-list-item-title>
											<span class="icon-select__item__icon">
												<span v-if="props.item.plain" class="icon-select__item__icon__plain">{{
													props.item.value
												}}</span>
												<v-icon v-else>{{ props.item.value }}</v-icon>
											</span>

											<span class="icon-select__item__text">{{ props.item.text }}</span>
										</v-list-item-title>
									</v-list-item-content>
								</template>
							</v-select>
						</v-flex>

						<v-flex xs3 ml-2>
							<v-menu>
								<template v-slot:activator="{ on }">
									<v-btn
										v-on="on"
										:style="{
											'background-color': item.iconColor,
										}"
										>{{ $t(`comp.noteBoard.editNoteDialog.color`) }}</v-btn
									>
								</template>

								<swatches v-model="item.iconColor" />
							</v-menu>
						</v-flex>
					</v-flex> -->

					<v-flex xs12 md9 pa-3>
						<v-text-field v-model="item.qrContent" :label="$t('comp.noteBoard.editNoteDialog.qrCodeData')" />
					</v-flex>

					<v-flex xs12 md3 pa-3 class="sticky-select-wrapper">
						<svg class="icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path
								d="M7 2h10v2l-2 1v5l3 3v3h-5v4l-1 3l-1-3v-4H6v-3l3-3V5L7 4z"
								fill-rule="evenodd"
								fill="rgba(0, 0, 0, 0.54)"
							/>
						</svg>
						<v-switch v-model="item.sticky" :label="$t(`comp.noteBoard.editNoteDialog.sticky`)"> </v-switch>
					</v-flex>
				</v-layout>

				<v-layout row wrap pa-3>
					<v-flex xs12>
						<sz-text-editor v-model="item.text"></sz-text-editor>
					</v-flex>
				</v-layout>
			</template>

			<template v-slot:actions-left>
				<v-btn text color="warning" @click="open = false">{{ $t("generic.actions.cancel") }}</v-btn>

				<div class="save-state">
					<div v-if="isFresh && !isDirty">
						<span>Empty note</span>
					</div>

					<div v-else-if="isFresh">
						<span>New, unsaved note</span>
						<v-icon>report_problem</v-icon>
					</div>

					<div v-else-if="isDirty">
						<span>Unsaved changes</span>
						<v-icon>report_problem</v-icon>
					</div>

					<div v-else>
						<span>Saved</span>
						<v-icon>check_circle</v-icon>
					</div>
				</div>
			</template>

			<template v-slot:actions-right>
				<v-select
					v-if="item.id"
					v-model="item.state"
					:label="$t(`generic.state.approvalStatus`)"
					solo
					prepend-icon="visibility"
					hide-details
					:items="[
						{ value: 'Draft', text: $t(`generic.state.draft`) },
						{ value: 'PendingApproval', text: $t(`generic.state.pendingApproval`) },
						{ value: 'Rejected', text: $t(`generic.state.notApproved`) },
						{ value: 'Published', text: $t(`generic.state.published`) },
					]"
				/>

				<span v-if="item.id">
					<v-btn text color="primary" @click="save()">{{ $t("generic.actions.update") }}</v-btn>
				</span>

				<span v-else>
					<v-btn text @click="save()">{{ $t("generic.actions.saveDraft") }}</v-btn>
					<v-btn text color="primary" @click="save(true)">{{ $t("generic.actions.publish") }}</v-btn>
				</span>
			</template>
		</sz-content-card>
	</v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import clone from "lodash/clone";

import { Note } from "@/models/Note";
import Swatches from "@/components/ColorPicker/Swatches.vue";

@Component({
	components: {
		Swatches,
	},
})
export default class EditNoteDialog extends Vue {
	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [Object] })
	note!: Note;

	item: Note | null = null;
	initialItem: Note | null = null;
	today = new Date();

	get open() {
		return this.value;
	}

	set open(value: boolean) {
		this.$emit("input", value);
	}

	get isFresh() {
		return this.item && !this.item.id;
	}

	get isDirty() {
		if (!this.item || !this.initialItem) return false;
		if (this.item.end !== this.initialItem.end) return true;
		if (this.item.start !== this.initialItem.start) return true;
		if (this.item.qrContent !== this.initialItem.qrContent) return true;
		if (this.item.sticky !== this.initialItem.sticky) return true;
		if (this.item.text !== this.initialItem.text) return true;
		return false;
	}

	@Watch("note", { immediate: true })
	onNoteChange() {
		this.item = {
			...{
				entityId: null,
				text: "",
				icon: null,
				iconColor: null,
				slot: "note",
				start: null,
				end: undefined,
				sticky: undefined,
				qrContent: null,
			},
			...clone(this.note),
		};

		this.initialItem = clone(this.item);
	}

	async save(publish = false) {
		if (!this.item) return;

		if (publish) {
			this.item.state = "Published";
		}

		// if (this.item.sticky && !this.item.end) this.item.end = moment.add(7, "days").calendar();

		await this.$store.dispatch(this.item.id ? "updateNote" : "createNote", this.item);

		this.open = false;
	}
}
</script>

<style lang="scss" scoped>
.meta-fields {
	& > * + * {
		margin: 24px 0 0 0 !important;
	}
}

.save-state {
	> div {
		display: flex;
		align-items: center;
		margin-left: 1rem;

		.v-icon {
			margin-left: 0.4rem;
		}
	}
}

.icon-select {
	display: flex;

	&__item {
		&__icon {
			color: rgba(0, 0, 0, 0.54);
			display: inline-block;
			margin: 0 10px 0 0;
			text-align: center;
			width: 24px;

			&__plain {
				font-size: 24px;
				font-weight: bold;
			}
		}
	}
}

.sticky-select-wrapper {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
</style>

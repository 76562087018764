<template>
	<v-card
		class="preview"
		v-if="event"
		@click="$listeners.click"
		@mouseenter="$listeners.mouseenter"
		@mouseleave="$listeners.mouseleave"
		:style="{
			top: `${position.top + offset.top}px`,
			left: `${position.left + offset.left}px`,
			'flex-direction': mobile ? 'column' : '',
		}"
	>
		<div class="preview__info">
			<p v-if="event.start" class="info-section">
				<i class="material-icons">today</i>&nbsp;

				<span>
					{{ event.start | moment("D. MMM Y") }}
				</span>
			</p>

			<p v-if="event.end" class="info-section">
				<i class="material-icons">event</i>&nbsp;

				<span>
					{{ event.end | moment("D. MMM Y") }}
				</span>
			</p>

			<p v-if="event.duration" class="info-section">
				<i class="material-icons">timelapse</i>&nbsp;
				<span> {{ event.duration }} {{ $t("generic.date.seconds") }} </span>
			</p>
		</div>

		<div v-if="imageUrl" class="template">
			<div
				class="image"
				:style="{
					backgroundImage: `url(${imageUrl})`,
				}"
			>
				<div class="text" :class="textClass" v-html="event.templateText"></div>
			</div>
		</div>
	</v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { getBannerTemplateByTemplateId } from "../TemplatesEditor/templates";

// function getObjectValue(key: string|null, obj: any): string|null {
// 	if (key === null) return null;

// 	const [first, ...rest] = key.split(".");

// 	if (first in obj) {
// 		if (typeof obj[first] === "object") {
// 			return getObjectValue(rest.join("."), obj[first]);
// 		}

// 		return obj[first];
// 	}

// 	return null;
// }

@Component
export default class TemplatePreview extends Vue {
	@Prop({ default: false, type: [Object, Boolean] })
	event!: any | boolean;

	@Prop({ required: true })
	position?: { top: number; left: number };

	@Prop({ default: true, type: Boolean })
	mobile!: boolean;

	/**
	 * Adjusts the preview position.
	 */
	offset: { top: number; left: number } = {
		top: -25,
		left: 15,
	};

	get imageUrl() {
		const template = getBannerTemplateByTemplateId(this.event.template);

		return template ? template.image : "";
	}

	get textClass() {
		const template = getBannerTemplateByTemplateId(this.event.template);

		return {
			"text--light": !template || template.fontColor === "light" ? true : false,
			"text--dark": template ? template.fontColor === "dark" : false,
		};
	}
}
</script>

<style lang="scss" scoped>
.preview {
	position: fixed;
	z-index: 999;
	cursor: pointer;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

	display: flex;
	// flex-direction: column;
	justify-content: space-between;

	min-width: 260px;

	.preview__info {
		min-width: 235px;
		display: flex;
		flex-direction: column;
		padding: 18px 12px;

		p {
			font-size: 17px;
			line-height: 1.5;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.info-section {
			display: flex;
		}
	}

	.template {
		width: 100%;
		height: 100%;
		min-width: 260px;

		.image {
			border-radius: 0 0 4px 4px;

			display: block;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			background-color: #464646;
			height: 0;
			padding-top: 895px / 480px * 100%;

			position: relative;

			.text {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				text-align: center;
				color: white;
				padding: 20px;
				font-size: 16px;
				line-height: 16px;

				> * {
					margin: 0 0 0.5em;
				}

				// h1 {
				// 	font-size: 2em;
				// 	line-height: 1em;
				// }
				// h2 {
				// 	font-size: 1.5em;
				// 	line-height: 1em;
				// }
				// ol, ul {
				// 	padding: 0;
				// 	list-style-position: inside;
				// }

				&--dark {
					color: #333;
				}
			}
		}
	}
}
</style>
